import { FaGithub } from 'react-icons/fa';
const content = {
   title: 'Our team.',
   subtitle: 'Compassion is at the heart of our care.',
   team: [
    {
      name: 'Dr. Thomas D. Parsons',
      creds: 'MD, CCFP, FCFP',
      about: `Dr. Parsons was born Newfoundland and raised in the
      maritimes graduated from Queen’s University. Over the last
      48 years, Dr. Parsons has helped the many communities he
      has lived in, from covering emergencies, delivering babies
      to working in an acute hospital environment. While at
      Langley Memorial Hospital, he held the position of Chief
      of Staff along with President of Medical Staff. He
      now works at HealthPoint Medical Clinic, where he is using
      his many years of experience to continue his Family
      Practice in the community he has called home for more than
      40 years.`,
    },
    {
      name: 'Dr. Matthew Parsons',
      creds: 'MD',
      about: `Dr. Parsons joined HealthPoint Medical in 2019, 
      bringing his extensive experience and a passion for personalized care. 
      He is deeply committed to making a positive impact on his patients’ health and well-being.`,
    },
    {
      name: 'Dr. Gagandeep Gill',
      creds: 'MD',
      about: `Dr. Gill became part of the HealthPoint Medical team in September 2023. 
      Known for his empathetic care and attention to detail,
      he strives to provide the best outcomes for his patients.`,
    },
    {
      name: 'Dr. Jasmin Khattar',
      creds: 'MD',
      about: `Dr. Khattar joined HealthPoint Medical in September 2024, 
      bringing her expertise and dedication to patient care to the team. 
      She strives to provide personalized treatment and support to every patient she serves.`,
    },
    {
      name: 'Alison Ireland',
      creds: 'Medical office administrator',
      about: `Alison was born and raised in Cloverdale, BC. She dove
      straight into the work force, and after sharing her
      passion for music and entertainment with everyone she
      helped, she decided she wanted to do more. While working
      as an MOA, Alison met Dr.Parsons, and the rest is history.
      Fast forward 10 years, 25 half marathons, 5 full
      marathons, and a recent love for cruises, Alison now calls
      HealthPoint Medical Clinic home. Alison is always ready to
      help patients when they come through the doors, and dont
      be shy to show her pictures of your dogs on your phone.`,
    },
   ],
 };

export default content;
