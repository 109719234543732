import { FaMapMarkerAlt, FaPhone, FaFax } from 'react-icons/fa';

const contact = {
  title: 'CONTACT US',
  links: [
    {
      to:
        'https://www.google.com/maps/dir//HealthPoint+Medical+Clinic/data=!4m8!4m7!1m0!1m5!1m1!1s0x5485d147da32247f:0xb747008462de37db!2m2!1d-122.6667686!2d49.1167757',
      text: `20055 Willowbrook Dr,\n\xa0\xa0\xa0\xa0\xa0Langley Twp, BC V2Y 2T5`,
      Icon: FaMapMarkerAlt,
      newTab: true,
      isLocal: false,
    },
    {
      to: 'tel:+1-604-259-2525',
      text: '604.259.2525',
      Icon: FaPhone,
      newTab: false,
      isLocal: false,
    },
    {
      to: 'tel:+1-604-546-1291',
      text: '604.546.1291',
      Icon: FaFax,
      newTab: false,
      isLocal: false,
    },
  ],
};
export { contact };
